import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCleanQueryString, headerValue } from "../channel/channelSlice";
import { axiosInterceptors } from "../../utils/api";

export const getAllPostReports = createAsyncThunk(
  "/report-post/",
  async (params, type, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params);
      const res = await axiosInterceptors.get(
        `report-on-post/?${queryString}`,
        headers
      );
      return res;
    } catch (e) {
      thunkAPI.rejectWithValue(e.responst.data);
    }
  }
);

export const getPostReportById = createAsyncThunk(
  "/report-post/reportBugById",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `report-on-post/?report_uuid=${uuid}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updatePostReport = createAsyncThunk(
  "/update-report-post/",
  async ({ reqBody, uuid }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.put(
        `report-on-post/${uuid}/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.reponse.data);
    }
  }
);

export const deletePostReportById = createAsyncThunk(
  "/report-post/deletePostReportById",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.delete(
        `report-on-post/${uuid}/`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const reportPostSlice = createSlice({
  name: "report-post",
  initialState: {
    reportPost: { isLoading: false, data: [], isRejected: false },
    reportPostById: { isLoading: false, data: {}, isRejected: false },
    deleteReportPost: { isLoading: false, isRejected: false },
    page: 1,
    pageSize: { value: 10 },
    filterStatus: "all",
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setFilterStatus: (state, { payload }) => {
      state.filterStatus = payload;
    },
  },
  extraReducers: {
    [getAllPostReports.pending]: (state) => {
      state.reportPost.isLoading = true;
      state.reportPost.isRejected = false;
    },
    [getAllPostReports.fulfilled]: (state, { payload }) => {
      state.reportPost.isLoading = false;
      state.reportPost.data = payload;
      state.reportPost.isRejected = false;
    },
    [getAllPostReports.rejected]: (state) => {
      state.reportPost.isLoading = false;
      state.reportPost.isRejected = false;
    },
    [getPostReportById.pending]: (state) => {
      state.reportPostById.isLoading = true;
      state.reportPostById.isRejected = false;
    },
    [getPostReportById.fulfilled]: (state, { payload }) => {
      state.reportPostById.isLoading = false;
      state.reportPostById.data = payload;
      state.reportPostById.isRejected = false;
    },
    [getPostReportById.rejected]: (state) => {
      state.reportPostById.isLoading = false;
      state.reportPostById.isRejected = false;
    },
    [deletePostReportById.pending]: (state) => {
      state.deleteReportPost.isLoading = true;
      state.deleteReportPost.isRejected = false;
    },
    [deletePostReportById.fulfilled]: (state) => {
      state.deleteReportPost.isLoading = false;
      state.deleteReportPost.isRejected = false;
    },
    [deletePostReportById.rejected]: (state) => {
      state.deleteReportPost.isLoading = false;
      state.deleteReportPost.isRejected = false;
    },
  },
});

export const { toggleSidebar, setPage, setPageSize, setFilterStatus } =
  reportPostSlice.actions;
export default reportPostSlice.reducer;
