import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import "../styles/sidebar.scss";
import { Disclosure, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ChevronDown from "../assets/Icons/ChevronDown";
import VoiceIcon from "../assets/Icons/VoiceIcon";
import ChannelIcon from "../assets/Icons/ChannelIcon";
import EpisodeIcon from "../assets/Icons/EpisodeIcon";
import ReportBugIcon from "../assets/Icons/ReportBug";
import ReportPostIcon from "../assets/Icons/ReportPostIcon";
import UserManagementIcon from "../assets/Icons/UserManagementIcon";
import AccessCodeIcon from "../assets/Icons/AccessCodeIcon";
import EmailCampIcon from "../assets/Icons/EmailCampIcon";
import SponcerContentIcon from "../assets/Icons/SponcerContentIcon";
import SponcerIcon from "../assets/Icons/SponcerIcon";
import DashboardIcon from "../assets/Icons/DashboardIcon";

// const isActive = (locationPath, menuItemPath, urlSubSets) =>
//   `${locationPath}/`
//     .split("/")
//     .filter(
//       (val) => menuItemPath.split("/").slice(0, urlSubSets).indexOf(val) !== -1
//     )
//     .join("/") === menuItemPath.split("/").slice(0, urlSubSets).join("/");

const isActive = (locationPath, menuItemPath, urlSubSets) => {
  const locationPathArray = `${locationPath}/`.split("/");
  const menuItemPathArray = menuItemPath.split("/").slice(0, urlSubSets);
  const filteredLocationPath = locationPathArray
    .filter((val) => menuItemPathArray.indexOf(val) !== -1)
    .join("/");
  const finalLocationPath = filteredLocationPath.endsWith("//")
    ? filteredLocationPath.slice(0, -1)
    : filteredLocationPath;
  const filteredMenuItemPath = menuItemPathArray.join("/");
  let isActive = finalLocationPath === filteredMenuItemPath;
  if (
    (locationPath === "/dashboard" || locationPath === "/") &&
    menuItemPath === "/dashboard"
  ) {
    return (isActive = true);
  } else {
    return isActive;
  }
};
const DropdownMenu = ({ item, sidebarExpanded }) => {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenDropdown(
      item.items &&
        item.items.filter(
          (menuItem) =>
            menuItem.path.split("/").slice(0, 3).join("/") ===
            location.pathname.split("/").slice(0, 3).join("/")
        ).length > 0
        ? true
        : false
    );
  }, [location.pathname]);

  if (!sidebarExpanded) {
    return (
      <div className="menu-item flex relative group cursor-pointer">
        <div
          className={`flex items-center ${
            item.items &&
            item?.items &&
            item?.items.filter(
              (menuItem) =>
                menuItem?.path?.split("/")?.slice(0, 3)?.join("/") ===
                location?.pathname?.split("/")?.slice(0, 3)?.join("/")
            ).length > 0
              ? "fill-current text-blue-500"
              : ""
          }`}
        >
          {item.icon}
          {sidebarExpanded && (
            <div className={`text-sm text-gray-700 dark:text-dark-100`}>
              {item.title}
            </div>
          )}
        </div>
        <div className="fixed w-64 bg-white dark:bg-dark-500 hidden group-hover:block  left-10  rounded-tr-md rounded-br-md shadow-md">
          {item.items &&
            item.items.map((menuItem, menuItemIndex) => (
              <div key={menuItemIndex}>
                <Link
                  to={menuItem.path}
                  className={`${
                    isActive(location.pathname, menuItem.path, 4)
                      ? "menu-item-selected"
                      : "menu-item"
                  }`}
                >
                  <div
                    className={`${
                      isActive(location.pathname, menuItem.path, 4)
                        ? " font-medium text-blue-500 "
                        : " text-gray-700 dark:text-dark-100"
                    } dark:hover:text-blue-500 hover:text-blue-500`}
                  >
                    {menuItem.title}
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <Disclosure
      defaultOpen={
        item.items &&
        item.items.filter(
          (menuItem) =>
            menuItem.path.split("/").slice(0, 3).join("/") ===
            location.pathname.split("/").slice(0, 3).join("/")
        ).length > 0
          ? true
          : false
      }
    >
      {({ open }) => (
        <>
          <div
            className={`menu-item ${
              item?.items &&
              item?.items?.filter(
                (menuItem) =>
                  menuItem?.path?.split("/")?.slice(0, 3).join("/") ===
                  location?.pathname?.split("/")?.slice(0, 3)?.join("/")
              ).length > 0
                ? "menu-item-selected"
                : "menu-item"
            }`}
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <Disclosure.Button
              className=" flex"
              style={{ width: sidebarExpanded ? "244px" : "40px" }}
              // static={false}
            >
              <div
                className={`flex items-center w-full font-semibold rounded-r-md ${
                  item?.items &&
                  item?.items?.filter(
                    (menuItem) =>
                      menuItem?.path?.split("/")?.slice(0, 3).join("/") ===
                      location?.pathname?.split("/")?.slice(0, 3)?.join("/")
                  ).length > 0
                    ? "fill-current text-blue-500 bg-blue-400 pr-3 rounded-l-md"
                    : ""
                }`}
              >
                <div
                  className={`flex justify-between w-full items-center bg-white py-1 rounded-r-md pl-4   ${
                    item?.items &&
                    item?.items.filter(
                      (menuItem) =>
                        menuItem?.path?.split("/")?.slice(0, 3)?.join("/") ===
                        location?.pathname?.split("/")?.slice(0, 3)?.join("/")
                    ).length > 0
                      ? "text-blue-500  shadow-sm"
                      : "text-gray-500"
                  }`}
                >
                  <div className="flex items-center text-base">
                    {item.icon}
                    {sidebarExpanded && <div className={` `}>{item.title}</div>}
                  </div>
                  {sidebarExpanded && (
                    <div className="block ml-auto">
                      <ChevronDown className="menu-icon fill-current" />
                    </div>
                  )}
                </div>
              </div>
            </Disclosure.Button>
          </div>
          <Transition show={openDropdown}>
            <Disclosure.Panel className="text-sm text-gray-700 dark:text-dark-100">
              {item.items &&
                item.items.map((menuItem, menuItemIndex) => (
                  <div
                    key={menuItemIndex}
                    open={false}
                    // onClick={() => dispatch(toggleNavigation())}
                  >
                    <Link
                      to={menuItem.path}
                      className={`${
                        isActive(location.pathname, menuItem.path, 4)
                          ? "menu-item-selected"
                          : "menu-item"
                      }`}
                    >
                      <button>
                        <div
                          className={`${
                            isActive(location.pathname, menuItem.path, 4)
                              ? "text-black font-medium"
                              : "group-hover:text-blue-500 group-focus:text-blue-500 text-gray-500"
                          } ml-10`}
                        >
                          {menuItem.title}
                        </div>
                      </button>
                    </Link>
                  </div>
                ))}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

const DashboardSidebar = () => {
  const { sidebarExpanded, showNavigation } = useSelector((state) => ({
    sidebarExpanded: state.dashboard.sidebarExpanded,
    showNavigation: state.dashboard.showNavigation,
    // questionReviewPage: state.reviewQuestions.questionReviewPage,
  }));
  const tabletView = window.matchMedia("(max-width: 768px)");

  const [matches, setMatches] = useState(tabletView.matches);
  const location = useLocation();
  const sidebarRef = useRef(null);
  // const [portrait, setPortrait] = useState(false)

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    tabletView.addListener(handler);
    const checkIfClickedOutside = (e) => {
      if (
        showNavigation &&
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target)
      ) {
        // dispatch(toggleNavigation());
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      tabletView.removeListener(handler);
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showNavigation]);

  // touchscreen

  const navigation = {
    dashboard: [
      {
        title: "HYPE",
        items: [
          {
            path: "/dashboard",
            title: "Dashboard",
            icon: <DashboardIcon className="menu-icon" />,
          },
          {
            path: "/channel/",
            title: "Channel",
            icon: <ChannelIcon className="menu-icon" />,
          },
          {
            path: "/podcast/",
            title: "Podcast",
            icon: <VoiceIcon className="menu-icon" />,
          },
          {
            path: "/episode/",
            title: "Episode",
            icon: <EpisodeIcon className="menu-icon" />,
          },
          {
            path: "/report-bug/",
            title: "Report Bug",
            icon: <ReportBugIcon className="menu-icon" />,
          },
          {
            path: "/report-post/",
            title: "Report Post",
            icon: <ReportPostIcon className="menu-icon" />,
          },
          {
            path: "/athlete-management/",
            title: "Athlete Management",
            icon: <UserManagementIcon className="menu-icon" />,
          },
          {
            path: "/user-management/",
            title: "User Management",
            icon: <UserManagementIcon className="menu-icon" />,
          },
          {
            path: "/invite-code/",
            title: "Access Code",
            icon: <AccessCodeIcon className="menu-icon" />,
          },
          {
            path: "/email-campaign/",
            title: "Email Campaign",
            icon: <EmailCampIcon className="menu-icon" />,
          },
          {
            path: "/sponsor/",
            title: "Sponsor",
            icon: <SponcerContentIcon className="menu-icon" />,
          },
          {
            path: "/sponsor-content/",
            title: "Sponsor Content",
            icon: <SponcerIcon className="menu-icon" />,
          },
        ],
      },
    ],
  };
  return (
    <nav
      className={` ${
        showNavigation ? "mobile-show" : "mobile-hidden"
      } sidebar dark:bg-dark-600 ${!matches && "tour-sidebar"} ${
        !sidebarExpanded && "sidebar-closed "
      } `}
    >
      <div
        className={`${
          showNavigation ? "block md:hidden  " : "hidden"
        } fixed top-0 left-0 right-0 bottom-0 bg-gray-900 bg-opacity-40  w-screen h-full z-50 md:z-0`}
      />
      <div
        // onClick={() => {
        //   if (questionReviewPage) {
        //     // dispatch(toggleReviewSidebar());
        //   } else {
        //     // dispatch(toggleSidebar());
        //   }
        // }}
        className="collapse-icon "
      >
        {/* {sidebarExpanded ? (
          <ChevronLeft className="h-6 w-6 fill-current  dark:text-white" />
        ) : (
          <ChevronRight className="h-6 w-6 fill-current dark:text-white" />
        )} */}
      </div>
      <div
        className={`hidden px-3 h-[90px] md:flex md:items-center justify-start ${
          sidebarExpanded ? "" : "md:justify-center"
        }`}
      >
        <img src={Logo} alt="logo" />
      </div>
      <div
        style={{ maxHeight: "94vh", minWidth: "48px" }}
        className="bg-white h-full dark:bg-dark-600 z-50 fixed overflow-y-auto"
        ref={sidebarRef}
      >
        {navigation.dashboard &&
          navigation.dashboard.map((group, groupIndex) => (
            <div
              key={groupIndex}
              className="block border-t w-[16rem] dark:border-dark-500 text-sm"
            >
              {group.items &&
                group.items.map((item, itemIndex) =>
                  item.type === "dropdown" ? (
                    <DropdownMenu
                      key={itemIndex}
                      item={item}
                      sidebarExpanded={sidebarExpanded}
                    />
                  ) : (
                    <Link
                      key={itemIndex}
                      to={item.path}
                      className={`pr-2.5 ${
                        isActive(location.pathname, item.path, 3)
                          ? "menu-item-selected"
                          : "menu-item"
                      }`}
                      // onClick={() => dispatch(toggleNavigation())}
                    >
                      <div
                        className={`w-full font-semibold rounded-r-md pr-3 ${
                          isActive(location.pathname, item.path, 3)
                            ? "bg-blue-400 rounded-l-md"
                            : ""
                        }`}
                      >
                        <div
                          className={`flex justify-between w-full items-center bg-white py-1 rounded-r-md pl-4 ${
                            isActive(location.pathname, item.path, 3)
                              ? "shadow-sm"
                              : ""
                          }`}
                        >
                          <div className="flex items-center gap-3 text-base">
                            <div className="w-[30px]">{item.icon}</div>
                            {sidebarExpanded && (
                              <div
                                className={`mr-2 ${
                                  isActive(location.pathname, item.path, 3)
                                    ? "text-blue-500 font-medium text-start"
                                    : "text-gray-500 dark:text-dark-100 text-start group-hover:text-blue-500 group-focus:text-blue-500 text-base"
                                } `}
                              >
                                {item.title}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                )}
            </div>
          ))}
      </div>
    </nav>
  );
};

export default DashboardSidebar;
