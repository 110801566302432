import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { getCleanQueryString } from "../channel/channelSlice";

export const getAllEmails = createAsyncThunk(
  "allEmailsCampaign",
  async (params, thunkAPI) => {
    try {
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `email-marketing/?${queryString}`
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const emailCampaignSlice = createSlice({
  name: "email-campaign",
  initialState: {
    emailCampaignData: { isLoading: false, data: [], isRejected: false },
    page: 1,
    pageSize: { value: 10 },
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: {
    [getAllEmails.pending]: (state) => {
      state.emailCampaignData.isLoading = true;
      state.emailCampaignData.isRejected = false;
    },
    [getAllEmails.fulfilled]: (state, { payload }) => {
      state.emailCampaignData.isLoading = false;
      state.emailCampaignData.data = payload;
      state.emailCampaignData.isRejected = false;
    },
    [getAllEmails.rejected]: (state) => {
      state.emailCampaignData.isLoading = false;
      state.emailCampaignData.isRejected = false;
    },
  },
});

export const { toggleSidebar, setPage, setPageSize } =
  emailCampaignSlice.actions;
export default emailCampaignSlice.reducer;
