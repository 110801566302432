import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { getCleanQueryString } from "../channel/channelSlice";
import { tokenValue } from "./../../utils/api";

export const headerValue = () => {
  return {
    Accept: "application/json",
    Authorization: tokenValue,
  };
};

export const getPodcast = createAsyncThunk(
  "dashboard/getPodcast",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `podcast-crud/?${queryString}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getPodcastByChannelId = createAsyncThunk(
  "dashboard/getPodcastByChannelId",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `hypecast/podcast-by-channel/?${queryString}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPodcastByCategory = createAsyncThunk(
  "dashboard/getPodcast/byCategory",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params.param2);
      const response = await axiosInterceptors.get(
        `hypecast/podcast-by-category/${params?.categoryId}/?${queryString}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getPodcastByChannel = createAsyncThunk(
  "dashboard/getPodcast/byCategory",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params.param2);
      const response = await axiosInterceptors.get(
        `hypecast/podcast-by-category/${params?.categoryId}/?${queryString}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPodcastById = createAsyncThunk(
  "dashboard/getPodcastById",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `podcast-crud/?podcast_uuid=${uuid}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updatePodcast = createAsyncThunk(
  "dashboard/updatePodcast",
  async ({ reqBody, uuid }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.put(
        `podcast-crud/${uuid}/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addPodcast = createAsyncThunk(
  "dashboard/addPodcast",
  async (reqBody, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.post(
        `podcast-crud/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deletePodcast = createAsyncThunk(
  "dashboard/deletePodcast",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.delete(
        `podcast-crud/${uuid}/`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteSelectedPodcast = createAsyncThunk(
  "dashboard/deleteSelectedPodcast",
  async (reqBody, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.post(
        `bulk-podcast-episode-deletion/?keyword=podcast_delete`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const podcastSlice = createSlice({
  name: "podcast",
  initialState: {
    podcast: { isLoading: false, data: [], isRejected: false },
    podcastById: { isLoading: false, data: {}, isRejected: false },
    podcastByChannelId: { isLoading: false, data: {}, isRejected: false },
    updatePodcast: { isLoading: false, data: [], isRejected: false },
    deletePodcast: { isLoading: false, isRejected: false },
    podcastDebounce: null,
    searchPodcast: "",
    page: 1,
    pageSize: { value: 10 },
    selectedType: "podcasts",
    searchTerm: "",
  },
  reducers: {
    clearPodcast: (state, { payload }) => {
      state.pageSize = { value: 10 };
      state.page = 1;
      state.searchPodcast = "";
      state.selectedType = "podcasts";
      state.searchTerm = "";
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSearchPodcast: (state, { payload }) => {
      state.searchPodcast = payload;
    },
    setSelectedType: (state, { payload }) => {
      state.selectedType = payload;
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
    },
    updatePodcastDebounce: (state, { payload }) => {
      state.podcastDebounce = payload;
    },
  },
  extraReducers: {
    [getPodcast.pending]: (state) => {
      state.podcast.isLoading = true;
      state.podcast.isRejected = false;
    },
    [getPodcast.fulfilled]: (state, { payload }) => {
      state.podcast.isLoading = false;
      state.podcast = { ...payload, data: payload?.data };
      state.podcast.isRejected = false;
    },
    [getPodcast.rejected]: (state) => {
      state.podcast.isLoading = false;
      state.podcast.isRejected = false;
    },
    [getPodcastByCategory.pending]: (state) => {
      state.podcast.isLoading = true;
      state.podcast.isRejected = false;
    },
    [getPodcastByCategory.fulfilled]: (state, { payload }) => {
      state.podcast.isLoading = false;
      state.podcast = { ...payload, data: payload?.data };
      state.podcast.isRejected = false;
    },
    [getPodcastByCategory.rejected]: (state) => {
      state.podcast.isLoading = false;
      state.podcast.isRejected = false;
    },
    [getPodcastByChannelId.pending]: (state) => {
      state.podcastByChannelId.isLoading = true;
      state.podcastByChannelId.isRejected = false;
    },
    [getPodcastByChannelId.fulfilled]: (state, { payload }) => {
      state.podcastByChannelId.isLoading = false;
      state.podcastByChannelId = { ...payload, data: payload?.data };
      state.podcastByChannelId.isRejected = false;
      state.podcast.isLoading = false;
      state.podcast = { ...payload, data: payload?.data };
      state.podcast.isRejected = false;
    },
    [getPodcastByChannelId.rejected]: (state) => {
      state.podcastByChannelId.isLoading = false;
      state.podcastByChannelId.isRejected = false;
    },
    [getPodcastById.pending]: (state) => {
      state.podcastById.isLoading = true;
      state.podcastById.isRejected = false;
    },
    [getPodcastById.fulfilled]: (state, { payload }) => {
      state.podcastById.isLoading = false;
      state.podcastById.data = payload.data;
      state.podcastById.isRejected = false;
    },
    [getPodcastById.rejected]: (state) => {
      state.podcastById.isLoading = false;
      state.podcastById.isRejected = false;
    },
    [addPodcast.pending]: (state) => {
      state.updatePodcast.isLoading = true;
      state.updatePodcast.isRejected = false;
    },
    [addPodcast.fulfilled]: (state, { payload }) => {
      state.updatePodcast.isLoading = false;
      state.updatePodcast.isRejected = false;
    },
    [addPodcast.rejected]: (state) => {
      state.updatePodcast.isLoading = false;
      state.updatePodcast.isRejected = false;
    },
    [updatePodcast.pending]: (state) => {
      state.updatePodcast.isLoading = true;
      state.updatePodcast.isRejected = false;
    },
    [updatePodcast.fulfilled]: (state, { payload }) => {
      state.updatePodcast.isLoading = false;
      state.updatePodcast.data = payload?.data;
      state.updatePodcast.isRejected = false;
    },
    [updatePodcast.rejected]: (state) => {
      state.updatePodcast.isLoading = false;
      state.updatePodcast.isRejected = false;
    },
    [deletePodcast.pending]: (state) => {
      state.deletePodcast.isLoading = true;
      state.deletePodcast.isRejected = false;
    },
    [deletePodcast.fulfilled]: (state, action) => {
      state.deletePodcast.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.podcast.data = state.podcast.data.filter(
          (category) => category?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deletePodcast.isRejected = false;
    },
    [deletePodcast.rejected]: (state) => {
      state.deletePodcast.isLoading = false;
      state.deletePodcast.isRejected = false;
    },
  },
});
export const {
  updatePodcastDebounce,
  setSearchPodcast,
  setPage,
  setPageSize,
  clearPodcast,
  setSelectedType,
  setSearchTerm,
} = podcastSlice.actions;
export default podcastSlice.reducer;
