import { configureStore } from "@reduxjs/toolkit";
import athleteSlice from "./athlete/athleteSlice";
import { authSlice } from "./auth/authSlice";
import coachSlice from "./coach/coachSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import emailCategorySlice from "./emailCategory/emailCategorySlice";
import generateAccessCodeSlice from "./generateAccessCode/generateAccessCodeSlice";
import podcastSlice from "./podcast/podcastSlice";
import episodeSlice from "./episode/episodeSlice";
import categoriesSlice from "./category/categorySlice";
import channelSlice from "./channel/channelSlice";
import guardianSlice from "./guardian/guardianSlice";
import ticketReportSlice from "./ticketReport/ticketReportSlice";
import sponsorSlice from "./sponsors/sponsorSlice";
import reportBugSlice from "./bug-report/report-bug";
import userProfileSlice from "./userProfile/userProfileSlice";
import emailCampaignSlice from "./email-campaign/emailCampaignSlice";
import userManagementSlice from "./userManagement/userManagementSlice";
import getAllSportsClassesSlice from "./userManagement/getAllSportsClassesSlice";
import reportPostSlice from "./report-post/reportPostSlice";
import sponsorDataSlice from "./sponsors/sponcerDataSlice";
import homeSlice from "./home/home";
import stateCitySlice from "./userManagement/stateCitySlice";

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    dashboard: dashboardSlice,
    home: homeSlice,
    accessCode: generateAccessCodeSlice,
    emailCategory: emailCategorySlice,
    podcast: podcastSlice,
    episode: episodeSlice,
    categories: categoriesSlice,
    channel: channelSlice,
    athlete: athleteSlice,
    ticket: ticketReportSlice,
    guardian: guardianSlice,
    coach: coachSlice,
    sponsor: sponsorSlice,
    reportBug: reportBugSlice,
    userProfile: userProfileSlice,
    emailCampaigns: emailCampaignSlice,
    userManagement: userManagementSlice,
    sportClasses: getAllSportsClassesSlice,
    reportPostSlice: reportPostSlice,
    sponsorData: sponsorDataSlice,
    stateCity: stateCitySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
