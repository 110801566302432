import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors, tokenValue } from "../../utils/api";
import { getCleanQueryString } from "../channel/channelSlice";

const initialState = {
  users: { isLoading: false, data: [], isRejected: false },
  searchUser: "",
  selectedUserType: "Select",
  userDebounce: null,
  page: 1,
  pageSize: { value: 10 },
  userDetail: { isLoading: false, isRejected: false, data: null },
};
export const headerValue = () => {
  return {
    Accept: "application/json",
    Authorization: tokenValue,
  };
};
export const getUserManagementList = createAsyncThunk(
  "usermanagement/getUserManagementList",
  async (params, thunkAPI) => {
    try {
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `user-management/?${queryString}`
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getUserDetails = createAsyncThunk(
  "usermanagement/getUserDetails",
  async ({ uuid }, thunkAPI) => {
    try {
      // let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `user-management/?user_uuid=${uuid}`
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getUserDetailsFile = createAsyncThunk(
  "usermanagement/getUserDetailsFile",
  async (params, thunkAPI) => {
    try {
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `user-management/?is_generate_csv=true&${queryString}`
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateUserStatus = createAsyncThunk(
  "dashboard/updateUserStatus",
  async ({ uuid, is_active }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.put(
        `user-management/${uuid}/?is_active=${is_active}`,
        {}
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateUserPersonal = createAsyncThunk(
  "dashboard/updateUserPersonal",
  async ({ formData, uuid }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.put(
        `user-personal-information/${uuid}/`,
        formData,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addUserPersonal = createAsyncThunk(
  "dashboard/addUserPersonal",
  async (formData, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.post(
        `user-management/`,
        formData
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateUserProfile = createAsyncThunk(
  "dashboard/updateUserProfile",
  async ({ dictObj, uuid }, thunkAPI) => {
    try {
      let headers = headerValue();

      const response = await axiosInterceptors.put(
        `athlete-profile/${uuid}/`,
        dictObj,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userManagementSlice = createSlice({
  name: "usermanagement",
  initialState: initialState,
  extraReducers: {
    [getUserManagementList.pending]: (state) => {
      state.users.isLoading = true;
      state.users.isRejected = false;
    },
    [getUserManagementList.fulfilled]: (state, { payload }) => {
      state.users = { ...payload, data: payload?.data };
      state.users.isLoading = false;
      state.users.isRejected = false;
    },
    [getUserManagementList.rejected]: (state, { payload }) => {
      state.users.isLoading = false;
      state.users.isRejected = true;
    },
    [getUserDetails.pending]: (state) => {
      state.userDetail.isLoading = true;
      state.userDetail.isRejected = false;
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      state.userDetail = { ...payload, data: payload?.data };
      state.userDetail.isLoading = false;
      state.userDetail.isRejected = false;
    },
    [getUserDetails.rejected]: (state, { payload }) => {
      state.userDetail.isLoading = false;
      state.userDetail.isRejected = true;
    },
  },
  reducers: {
    clearUser: (state, { payload }) => {
      state.pageSize = { value: 10 };
      state.page = 1;
      state.searchUser = "";
      state.selectedUserType = "Select";
    },
    setSearchUser: (state, { payload }) => {
      state.searchUser = payload;
    },
    setSelectedUserType: (state, { payload }) => {
      state.selectedUserType = payload;
    },
    updateUserDebounce: (state, { payload }) => {
      state.userDebounce = payload;
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});
export const {
  clearUser,
  setSearchUser,
  updateUserDebounce,
  setPageSize,
  setPage,
  setSelectedUserType,
} = userManagementSlice.actions;
export default userManagementSlice.reducer;
