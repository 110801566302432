import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { headerValue } from "../podcast/podcastSlice";
import { axiosInterceptors } from "../../utils/api";

export const getAllStates = createAsyncThunk(
  "dashboard/states",
  async (thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(`/states/`, headers);
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllCityByStateId = createAsyncThunk(
  "dashboard/cities",
  async (stateId, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `/city/${stateId}/`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const stateCitySlice = createSlice({
  name: "stateCity",
  initialState: {
    states: { isLoading: false, data: [], isRejected: false },
    cities: { isLoading: false, data: [], isRejected: false },
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
  },
  extraReducers: {
    [getAllStates.pending]: (state) => {
      state.states.isLoading = true;
      state.states.isRejected = false;
    },
    [getAllStates.fulfilled]: (state, { payload }) => {
      state.states.isLoading = true;
      state.states.data = payload.data;
      state.states.isRejected = false;
    },
    [getAllStates.rejected]: (state) => {
      state.states.isLoading = false;
      state.states.isRejected = false;
    },
    [getAllCityByStateId.pending]: (state) => {
      state.cities.isLoading = true;
      state.cities.isRejected = false;
    },
    [getAllCityByStateId.fulfilled]: (state, { payload }) => {
      state.cities.isLoading = true;
      state.cities.data = payload?.data;
      state.cities.isRejected = false;
    },
    [getAllCityByStateId.rejected]: (state) => {
      state.cities.isLoading = false;
      state.cities.isRejected = true;
    },
  },
});

export const { toggleSidebar } = stateCitySlice.actions;
export default stateCitySlice.reducer;
