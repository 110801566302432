import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { getCleanQueryString, headerValue } from "../channel/channelSlice";

export const getAllBugReports = createAsyncThunk(
  "/report-bug/",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params);
      const res = await axiosInterceptors.get(
        `report-bug/?${queryString}`,
        headers
      );
      return res;
    } catch (e) {
      thunkAPI.rejectWithValue(e.reponse.data);
    }
  }
);

export const getBugReportById = createAsyncThunk(
  "/report-bug/reportBugById",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `report-bug/?report_uuid=${uuid}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateReportBug = createAsyncThunk(
  "/update-report-bug/",
  async ({ reqBody, uuid }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.put(
        `report-bug/${uuid}/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.reponse.data);
    }
  }
);

export const AddMedia = createAsyncThunk(
  "reportIssue/issue",
  async (reqBody, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(
        `report-bug/add-media/`,
        reqBody
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteBugReportById = createAsyncThunk(
  "/report-bug/deleteReportBugById",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.delete(
        `report-bug/${uuid}/`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const reportBugSlice = createSlice({
  name: "report-bug",
  initialState: {
    reportBug: { isLoading: false, data: [], isRejected: false },
    reportBugById: { isLoading: false, data: {}, isRejected: false },
    deleteReportBug: { isLoading: false, isRejected: false },
    page: 1,
    pageSize: { value: 10 },
    filterStatusBug: "",
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setFilterStatusBug: (state, { payload }) => {
      state.filterStatusBug = payload;
    },
  },
  extraReducers: {
    [getAllBugReports.pending]: (state) => {
      state.reportBug.isLoading = true;
      state.reportBug.isRejected = false;
    },
    [getAllBugReports.fulfilled]: (state, { payload }) => {
      state.reportBug.isLoading = false;
      state.reportBug.data = payload;
      state.reportBug.isRejected = false;
    },
    [getAllBugReports.rejected]: (state) => {
      state.reportBug.isLoading = false;
      state.reportBug.isRejected = false;
    },
    [getBugReportById.pending]: (state) => {
      state.reportBugById.isLoading = true;
      state.reportBugById.isRejected = false;
    },
    [getBugReportById.fulfilled]: (state, { payload }) => {
      state.reportBugById.isLoading = false;
      state.reportBugById.data = payload;
      state.reportBugById.isRejected = false;
    },
    [getBugReportById.rejected]: (state) => {
      state.reportBugById.isLoading = false;
      state.reportBugById.isRejected = false;
    },
    [deleteBugReportById.pending]: (state) => {
      state.deleteReportBug.isLoading = true;
      state.deleteReportBug.isRejected = false;
    },
    [deleteBugReportById.fulfilled]: (state) => {
      state.deleteReportBug.isLoading = false;
      state.deleteReportBug.isRejected = false;
    },
    [deleteBugReportById.rejected]: (state) => {
      state.deleteReportBug.isLoading = false;
      state.deleteReportBug.isRejected = false;
    },
  },
});

export const { toggleSidebar, setPage, setPageSize, setFilterStatusBug } =
  reportBugSlice.actions;
export default reportBugSlice.reducer;
